import React from 'react';

import { Modal, Button, Table, Tag, Card, Empty } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useQuery } from 'react-query';

import AssetService from '../../APIServices/AssetService';
import { ServiceMappingType } from '../../models/ServiceMappingType';
import SingleAsset from '../NCR/SingleAsset/SingleAsset';

interface ServiceMappingModalProps {
  setMappingModal: (value: boolean) => void;
  mappingModal: boolean;
  assetClass: string;
  customerNbr: string;
}

type ServiceMapping = {
  id: string;
  serviceNbr: string;
  serviceType: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  processed: boolean;
};

const ServiceMappingModal: React.FC<ServiceMappingModalProps> = ({
  setMappingModal,
  mappingModal,
  assetClass,
  customerNbr,
}) => {
  const {
    data: serviceMapping,
    isLoading: isLoadingServiceMapping,
    refetch,
  } = useQuery(['serviceMapping', customerNbr, assetClass], () =>
    AssetService.getAssetServiceMapping(assetClass, customerNbr!)
  );

  const columns: ColumnsType<ServiceMappingType> = [
    {
      title: 'Asset Class',
      dataIndex: 'assetClass',
      key: 'assetClass',
    },
    {
      title: 'Service ID',
      dataIndex: 'serviceId',
      key: 'serviceId',
      render: (service: ServiceMapping) => {
        return <span>{service.serviceNbr}</span>;
      },
    },

    {
      title: 'Annual Rate',
      dataIndex: 'annualRate',
      key: 'annualRate',
      render: (text) => {
        return <span>{text ? `$${text}` : ''}</span>;
      },
    },
    {
      title: 'Coverage',
      dataIndex: 'coverage',
      key: 'coverage',
    },
    {
      title: 'Service description',
      dataIndex: 'serviceDescription',
      key: 'serviceDescription',
    },
    {
      title: 'Status',
      dataIndex: 'isactive',
      key: 'isactive',
      render: (text) => {
        return (
          <Tag color={text ? 'green' : 'red'}>
            {text ? 'Active' : 'Inactive'}
          </Tag>
        );
      },
    },
  ];

  return (
    <Modal
      title="Entitlement Mapping"
      open={mappingModal}
      onCancel={() => setMappingModal(false)}
      destroyOnClose
      width="90%"
      footer={[
        <Button key="back" size="large" onClick={() => setMappingModal(false)}>
          Close
        </Button>,
      ]}
    >
      <Card>
        <Table
          columns={columns}
          id="recent-uploads"
          rowKey={(record) => record.id!}
          dataSource={serviceMapping?.data}
          loading={isLoadingServiceMapping}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                description={`No entitlements available for this asset class: ${assetClass} with a customer number: ${customerNbr}`}
              />
            ),
          }}
        />
      </Card>
    </Modal>
  );
};

export default ServiceMappingModal;
