import React from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';

import { SliamUploaderConstants } from '../../../utils/constants';

type Props = {
  searchField: string;
  searchFieldSub: string;
  setSearchTerms: (e: string) => void;
  setMainSearchTerms: (e: string) => void;
  clearSearchFilter: () => void;
};

const SearchFilterDropDown = ({
  searchField,
  setMainSearchTerms,
  searchFieldSub,
  setSearchTerms,
  clearSearchFilter,
}: Props) => {
  const { Option } = Select;
  return (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setMainSearchTerms(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {SliamUploaderConstants.SEARCH_FIELDS?.map((item) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
      {searchField === SliamUploaderConstants.PROGRESS_STATUS && (
        <>
          <Select
            defaultValue="Select Field"
            placeholder="Select Field"
            value={searchFieldSub}
            onChange={(e) => setSearchTerms(e)}
            style={{ width: 200 }}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            {SliamUploaderConstants.STATUS_DROPDOWNS?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </>
      )}
      {searchField === SliamUploaderConstants.FILE_TYPE && (
        <>
          <Select
            defaultValue="Select Field"
            placeholder="Select Field"
            value={searchFieldSub}
            onChange={(e) => setSearchTerms(e)}
            style={{ width: 200 }}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            {SliamUploaderConstants.TYPE_DROPDOWNS?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </>
      )}
      {searchField === SliamUploaderConstants.PROGRESS_STATUS ||
      searchField === SliamUploaderConstants.FILE_TYPE ? (
        <Button
          type="primary"
          size="small"
          className="clear-button"
          onClick={clearSearchFilter}
        >
          Clear Filter
          <CloseCircleOutlined />
        </Button>
      ) : null}
    </div>
  );
};

export default SearchFilterDropDown;
